.order {
  width: 480px;

  @media screen and (max-width: 1024px) {
    margin: 0 auto;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &__wrapper {
    padding-top: 85px;
  }

  &__container {
    margin-bottom: 35px;
    padding: 65px 130px;
    position: relative;
    background-color: $themeColor;

    @media screen and (max-width: 940px) {
      padding: 40px 70px;
    }

    @media screen and (max-width: 767px) {
      padding: 30px 10px;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;
  }

  &__form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__form-input {
    width: 267px;
    padding-left: 21px;
    line-height: 53px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    outline: none;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__form-button {
    width: 194px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 53px;
    border: none;
    border-radius: 30px;
    background-color: $themeColor2;
    color: $whiteColor;
  }

  &__form-text {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 14px;
    color: #c68f2f;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  &__alt {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;

    &::before,
    &::after {
      width: 40.625%;
      height: 2px;
      display: inline-flex;
      content: '';
      background-color: #a67520;
    }
  }

  &__phone {
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    text-align: center;

    a {
      font-family: inherit;
      text-decoration: none;
      color: inherit;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }
  }

  &__image-container {
    position: absolute;
    right: 100px;
    bottom: -4px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}