.credits {
  &__container {
    padding-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
    color: rgba($blackColor,0.52);

    a {
      text-decoration: underline;
      color: inherit;
    }

    @media screen and (max-width: 767px) {
      height: 140px;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__copyrights-container {
    width: 360px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 900px) {
      width: 300px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}