.main {
  width: 100%;
}

.section {
  width: 100%;

  &--inner {
    width: 100%;
    max-width: 1180px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &--top {
    min-height: 815px;
    background: url('/assets/images/top-bg.png') no-repeat 50% 50% #0a0a0a;
    background-size: cover;
  }

  &--cost {
    min-height: 696px;
    background: url('/assets/images/cost-bg.png') no-repeat 50% 50% #0a0a0a;
    background-size: cover;
  }

  &--about {
    min-height: 498px;
    padding-top: 80px;
    background: url('/assets/images/about-bg.png') no-repeat 50% 50% #0a0a0a;
    background-size: cover;
  }
}

.selection {
  color: $themeColor;
}

@mixin transition($time: 0.3s) {
  transition: all $time linear;
}

.player {
  position: absolute;
  top: -10000px;
}

.scrolless {
  overflow: hidden;
}