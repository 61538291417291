@font-face {
  font-family: 'HelveticaNeueCyr';
  src: url('/fonts/HelveticaNeueCyr-Roman.eot');
  src: local('HelveticaNeueCyr-Roman'),
  url('/fonts/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HelveticaNeueCyr-Roman.woff2') format('woff2'),
  url('/fonts/HelveticaNeueCyr-Roman.woff') format('woff'),
  url('/fonts/HelveticaNeueCyr-Roman.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueCyrBold';
  src: url('/fonts/HelveticaNeueCyr-Bold.eot');
  src: local('HelveticaNeueCyr-Bold'),
  url('/fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'),
  url('/fonts/HelveticaNeueCyr-Bold.woff') format('woff'),
  url('/fonts/HelveticaNeueCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}