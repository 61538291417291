@import "_fonts";
@import "_variables";
@import "_helpers";

body,
body *,
body *::before,
body *::after {
  margin: 0;
  padding: 0;
  font-family: 'HelveticaNeueCyr', sans-serif;
  box-sizing: border-box;
}

@import "_section-top";
@import "_section-action";
@import "_section-cost";
@import "_section-feedback";
@import "_section-about";
@import "_section-order";
@import "_section-credits";
@import "_popup";