.feedback {
  &__section-title {
    margin-bottom: 45px;
    padding-top: 75px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }

  &__items-container {
    margin-bottom: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: 1140px) {
      justify-content: center;
    }
  }

  &__item {
    width: 555px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;

    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-bottom: 0;

      @media screen and (max-width: 1140px) {
        margin-bottom: 40px;
      }
    }

    @media screen and (max-width: 1140px) {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 767px) {
      width: 300px;
      flex-direction: column;
    }
  }

  &__image {
    max-width: 100%;
    align-self: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__item-content {
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__item-title {
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 32px;
  }

  &__item-date {
    margin-bottom: 40px;
    font-size: 16px;
  }

  &__audio-container {
    display: flex;
    flex-direction: row;
  }

  &__audio-icon {
    width: 76px;
    height: 76px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    background-color: $themeColor;

    &:before {
      width: 0;
      height: 0;
      display: block;
      content: '';
      position: absolute;
      left: 55%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-top: 10px solid transparent;
      border-right: none;
      border-bottom: 10px solid transparent;
      border-left: 20px solid $blackColor;
    }

    &--pause {
      &::before {
        width: 5px;
        height: 30px;
        display: block;
        content: '';
      }
    }
  }

  &__audio-info-container {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__audio-title {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 22px;
  }

  &__audio-duration {
    font-size: 16px;
    color: rgba($blackColor,0.39);
  }
}