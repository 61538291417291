.action {
  &__wrapper {
    padding: 90px 0 95px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1140px) {
      display: block;

      &::after {
        width: 0;
        height: 0;
        display: table;
        content: '';
        clear: both;
      }
    }

    @media screen and (max-width: 900px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__conditions-wrapper {
    width: 360px;

    @media screen and (max-width: 1140px) {
      width: 100%;
    }

    @media screen and (max-width: 900px) {
      margin-bottom: 30px;
    }
  }

  &__conditions-time {
    margin-bottom: 40px;
    padding: 20px 35px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    line-height: 55px;
    background-color: $themeColor2;
    color: $whiteColor;

    @media screen and (max-width: 1140px) {
      width: 100%;
    }
  }

  &__conditions-title {
    margin-bottom: 45px;
    font-size: 25px;
    line-height: 32px;
  }

  &__conditions-selection {
    font-weight: 700;
  }

  &__conditions-text {
    font-size: 14px;
    line-height: 22px;
    opacity: 0.53;
  }

  &__gallery-wrapper {
    width: 360px;

    @media screen and (max-width: 1140px) {
      width: 45%;
      float: left;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
      float: none;
    }
  }

  &__big-image {
    margin-bottom: 25px;
    display: block;

    img {
      width: 100%;
    }
  }

  &__small-images-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__form-wrapper {
    width: 360px;
    padding: 28px 28px 30px;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    text-align: center;
    border-radius: 3px;
    background-color: $themeColor;

    @media screen and (max-width: 1140px) {
      width: 45%;
      float: right;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      float: none;
      align-self: center;
    }
  }

  &__form-title {
    margin-bottom: 20px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 30px;
    font-weight: 700;
  }

  &__form-sub-title {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 22px;
  }

  &__form {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
  }

  &__form-input {
    margin-bottom: 20px;
    padding: 0 21px;
    line-height: 53px;
    font-size: 16px;
    text-align: center;
    border: none;
    border-radius: 3px;
    outline: none;
  }

  &__form-button {
    height: 64px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    border: none;
    border-radius: 30px;
    background-color: $themeColor2;
    color: $whiteColor;
  }

  &__form-text {
    font-size: 12px;
    line-height: 14px;
    color: #ce9633;

    a {
      text-decoration: underline;
      color: inherit;
    }
  }
}