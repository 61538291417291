// Верхний контейнер
.top-info {
  margin-bottom: 18px;
  padding-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // Ссылка с логотипом
  &__link {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $whiteColor;
  }

  // Название
  &__link-text {
    margin-left: 13px;
    font-size: 22px;
    text-transform: uppercase;
  }

  //Время работы
  &__time {
    font-size: 16px;
    line-height: 22px;
    color: $whiteColor;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  // Контейнер с телефоном
  &__phone-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 10px 20px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: rgba($blackColor,0.7);
    }
  }

  // Телефон
  &__phone {
    margin-bottom: 6px;
    font-size: 22px;
    text-decoration: none;
    color: $whiteColor;
  }

  // Заказать звонок
  &__order-call {
    cursor: pointer;
    color: $themeColor;
  }
}

// Меню
.navigation {
  height: 41px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  border-radius: 3px;
  background-color: #282828;

  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
    padding-left: 20px;
    justify-content: flex-start;
    visibility: hidden !important;

    &.cloned {
      visibility: visible !important;
    }
  }

  &--show {
    height: 100vh;
    padding-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .navigation__link {
      width: 50%;
      padding: 10px 0;
      display: block;
      border-bottom: 1px solid $whiteColor;

      &:first-of-type {
        margin-top: 40px;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  // Ссылка меню
  &__link,
  &__order {
    cursor: pointer;
    line-height: 22px;
    text-decoration: none;
    color: $whiteColor;
    @include transition();

    &:hover {
      color: $themeColor;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__menu {
    display: none;
    cursor: pointer;
    align-items: center;
    color: $whiteColor;

    &-icon {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      display: inline-flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    &-line {
      width: 30px;
      height: 3px;
      display: inline-flex;
      background-color: $whiteColor;
    }

    @media screen and (max-width: 767px) {
      display: inline-flex;
    }
  }
}

// Контейнер с заголовком и формой
.top-utp {
  &__container {
    margin-bottom: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1140px) {
      flex-direction: column;
    }

    @media screen and (max-width: 940px) {
      align-items: center;
    }
  }

  &__title-container {
    width: 540px;
    color: $whiteColor;

    @media screen and (max-width: 1140px) {
      width: 100%;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 45px;
    line-height: 55px;
    font-weight: 700;

    @media screen and (max-width: 1140px) {
      margin-bottom: 10px;
    }
  }

  &__sub-title {
    font-size: 25px;

    @media screen and (max-width: 1140px) {
      margin-bottom: 40px;
    }
  }

  &__form-container {
    width: 555px;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__form-container {
    padding: 26px 37px 29px;
    border-radius: 3px;
    background-color: $themeColor;
  }

  &__form-title {
    margin-bottom: 25px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__form {
    width: 100%;
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__form-input {
    width: 267px;
    padding-left: 21px;
    line-height: 53px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    outline: none;

    @media screen and (max-width: 900px) {
      margin-bottom: 20px;
    }
  }

  &__form-button {
    width: 194px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 53px;
    border: none;
    border-radius: 30px;
    background-color: $themeColor2;
    color: $whiteColor;
  }

  &__form-text {
    font-size: 12px;
    color: #c68f2f;
  }
}

//
.top-advantage {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1140px) {
      padding-right: 140px;
      flex-direction: row-reverse;
    }

    @media screen and (max-width: 940px) {
      padding-right: 0;
      flex-direction: column;
      align-items: center;
    }
  }

  &__banner {
    width: 387px;
    padding: 20px 30px;
    align-self: flex-start;
    position: relative;
    border-radius: 3px;
    background-color: $themeColor;

    @media screen and (max-width: 940px) {
      margin-bottom: 40px;
      align-self: center;
    }

    @media screen and (max-width: 657px) {
      text-align: center;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  &__banner-title {
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 40px;
    font-weight: 700;
  }

  &__banner-text {
    font-size: 25px;
  }

  &__banner-selection {
    text-decoration: underline;
  }

  &__banner-img {
    position: absolute;
    top: 50%;
    right: -155px;
    transform: translateY(-50%);

    @media screen and (max-width: 740px) {
      display: none;
    }
  }

  &__list {
    width: 555px;
    display: flex;
    flex-direction: row;
    list-style-type: none;

    @media screen and (max-width: 1140px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__item {
    width: 165px;
    margin-right: 30px;
    padding-top: 100px;
    background-repeat: no-repeat;
    background-position: 0 0;
    color: $whiteColor;

    &:last-child {
      margin-right: 0;
    }

    &:nth-of-type(1) {
      background-image: url('/assets/images/advantage-icon-1.png');
    }

    &:nth-of-type(2) {
      background-image: url('/assets/images/advantage-icon-2.png');
      background-position: 0 10px;
    }

    &:nth-of-type(3) {
      background-image: url('/assets/images/advantage-icon-3.png');
    }

    @media screen and (max-width: 1140px) {
      width: 100%;
      height: 80px;
      margin-bottom: 30px;
      padding-top: 0;
      padding-left: 120px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
    }

    @media screen and (max-width: 767px) {
      height: 90px;
      padding-left: 90px;
      background-size: 70px;
    }
  }

  &__item-title {
    margin-bottom: 18px;
    font-size: 25px;

    @media screen and (max-width: 1140px) {
      width: 100%;
    }
  }

  &__item-text {
    font-size: 16px;
    line-height: 22px;

    @media screen and (max-width: 1140px) {
      width: 100%;
    }
  }
}
