.cost {
  &__section-title {
    margin-bottom: 45px;
    padding-top: 75px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1140px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__item {
    width: 262px;
    text-align: center;

    @media screen and (max-width: 1140px) {
      width: 400px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 900px) {
      width: 310px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__image {
    max-width: 100%;
    margin-bottom: 30px;
    align-self: center;
  }

  &__title {
    margin-bottom: 20px;
    font-size: 25px;
    line-height: 32px;
  }

  &__price {
    margin-bottom: 22px;
    font-size: 16px;
    line-height: 22px;
  }

  &__button {
    width: 194px;
    height: 53px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 30px;
    outline: none;
    background-color: $themeColor2;
    color: $whiteColor;
  }
}