.about {
  &__section-title {
    margin-bottom: 55px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 100px;
    font-weight: 700;
    line-height: 55px;
    color: rgba($whiteColor,0.2);

    @media screen and (max-width: 1140px) {
      text-align: center;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 1140px) {
      flex-direction: column-reverse;
    }
  }

  &__achieve-list {
    width: 540px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style-type: none;

    @media screen and (max-width: 1140px) {
      width: 100%;
      margin: 0 auto;
    }

    @media screen and (max-width: 900px) {
      width: 700px;
      justify-content: space-around;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__achieve-item {
    width: 210px;
    margin-bottom: 65px;

    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-bottom: 0;

      @media screen and (max-width: 1140px) {
        margin-bottom: 60px;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: 1140px) {
      margin-bottom: 60px;
    }

    @media screen and (max-width: 900px) {
      width: 240px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  &__achieve-title {
    font-size: 70px;
    line-height: 55px;
    color: $whiteColor;

    &::after {
      width: 37px;
      height: 5px;
      margin-top: 15px;
      margin-bottom: 20px;
      display: block;
      content: '';
      background-color: $themeColor;
    }

    @media screen and (max-width: 767px) {
      display: inline-flex;
      flex-direction: row;

      &::after {
        width: 5px;
        height: 50px;
        margin: 0 10px;
      }
    }
  }

  &__achieve-text {
    font-size: 16px;
    line-height: 22px;
    color: $whiteColor;
  }

  &__form-container {
    width: 555px;

    @media screen and (max-width: 1140px) {
      margin: 0 auto 40px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  &__form-container {
    padding: 26px 37px 29px;
    border-radius: 3px;
    background-color: $themeColor;
  }

  &__form-title {
    margin-bottom: 25px;
    font-family: 'HelveticaNeueCyrBold', sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }

  &__form {
    width: 100%;
    margin-bottom: 20px;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__form-input {
    width: 267px;
    padding-left: 21px;
    line-height: 53px;
    font-size: 16px;
    border: none;
    border-radius: 3px;
    outline: none;

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  &__form-button {
    width: 194px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 53px;
    border: none;
    border-radius: 30px;
    background-color: $themeColor2;
    color: $whiteColor;
  }

  &__form-text {
    font-size: 12px;
    color: #c68f2f;
  }
}